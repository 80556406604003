import styled from 'styled-components';

const Main = styled.div`
  position: absolute;
  background: #282c34bd;
  box-shadow: 0px 0px 40px 40px #282c34bd;
  border-radius: 60px;
  user-select: none;
  pointer-events: none;
  padding: 20px 40px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const Title = styled.div`
  font-size: 40px;
  color: white;
`;

export default function Header() {
  return (
    <Main>
      <Title>Launching soon or not... because we do not care</Title>
    </Main>
  );
}
