import Header from './Header';
import TileContainer from './TileContainer';
import { useState, useEffect } from 'react';
import styled from 'styled-components';

const Main = styled.div`
  background: #282c34;
  min-height: 100vh;
  display: flex;
  overflow: hidden;
`;

export default function App() {
  const [seed, setSeed] = useState<number>(0);

  useEffect(() => {
    let initialValue = 0;
    setInterval(() => setSeed(++initialValue % 10_000), 5_000);
  }, []);

  return (
    <Main>
      <TileContainer seed={seed} />
      <Header />
    </Main>
  );
}
