import { useState, useEffect } from 'react';
import styled from 'styled-components';

const BG_COLORS = [
  '#969696',
  '#f03434',
  '#663399',
  '#22a7f0',
  '#00e640',
  '#d35400',
  '#f5ab35',
  '#86e2d5',
  '#1f3a93',
];

interface MainProps {
  size: number;
  color: string;
  x: number;
  y: number;
}

const Main = styled.div.attrs<MainProps>(({ color, size, x, y }) => ({
  style: {
    left: `${x}px`,
    top: `${y}px`,
    height: `${size}px`,
    width: `${size}px`,
    background: color,
  },
}))<MainProps>``;

function pickColor(): string {
  return BG_COLORS[Math.floor(Math.random() * BG_COLORS.length)];
}

export default function Tile({
  seed,
  size,
  x,
  y,
}: {
  seed: number;
  size: number;
  x: number;
  y: number;
}) {
  const [fixedColorId, setFixedColorId] = useState<number>(-2);
  const [color, setColor] = useState<string>(pickColor());

  useEffect(() => {
    if (fixedColorId <= -2 && Math.random() > 0.5) {
      setColor(pickColor());
    }
  }, [fixedColorId, seed]);

  return (
    <Main
      className="tile"
      onClick={() => {
        const newColorId = fixedColorId + 1;
        setFixedColorId(newColorId);

        if (newColorId < 0) {
          setColor('#ffd700');
        } else {
          setColor(BG_COLORS[newColorId % BG_COLORS.length]);
        }
      }}
      color={color}
      size={size}
      x={x}
      y={y}
    ></Main>
  );
}
