import Tile from './Tile';
import { useState, useEffect } from 'react';

const TILES_SIZE_PX = 50;

export default function TileContainer({ seed }: { seed: number }) {
  const [tiles, setTiles] = useState<JSX.Element[]>([]);
  const [innerWidth, setInnerWidth] = useState<number>(0);
  const [innerHeight, setInnerHeight] = useState<number>(0);

  useEffect(() => {
    function setWindowSizes() {
      const { innerWidth, innerHeight } = window;
      setInnerWidth(innerWidth);
      setInnerHeight(innerHeight);
    }

    setWindowSizes();
    window.addEventListener('resize', setWindowSizes, true);
  }, []);

  useEffect(() => {
    const tilesElements = [];

    for (let height = 0; height < innerHeight; height += TILES_SIZE_PX) {
      for (let width = 0; width < innerWidth; width += TILES_SIZE_PX) {
        tilesElements.push(
          <Tile
            key={tilesElements.length}
            seed={seed}
            size={TILES_SIZE_PX}
            x={width}
            y={height}
          />
        );
      }
    }

    setTiles(tilesElements);
  }, [seed, innerHeight, innerWidth]);

  return <div>{tiles}</div>;
}
